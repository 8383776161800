import { localStorageConstants } from '../../constants/LocalStorageConstants';
import { messageConstants } from '../../constants/MsgConstants';
import { removeLeadingZeros, toCapitalize } from '../../utils/common';
import { clear, getItem, setItem } from '../../utils/storage';
import { getUserRole } from '../../dao/user/userrole';
import { fetchUserProfileData } from '../../dao/user/userprofiledata';
import { getDivisionName, getRequestedPath } from '../../utils/userprofile';
import { setUserLoginData, setUserLogoutData } from '../../utils/userTelemetry';
import { auth } from "../../dao/user/externaluser";

export default class Authentication {

  login = (redirectToLogin = true) => {
    if(redirectToLogin) {
      clear(); // clean state before redirecting to login page
      window.location.href = "/login";
    } else {
      setItem(localStorageConstants.USER_LOGIN, true);
      window.location.href = window.ENV.REACT_APP_IAM_AUTHENTICATION_URL;
    }
  }

  getUserProfile = async (type, params) => {
    const response = await fetchUserProfileData(type, params);
    if (response) {
      setItem(localStorageConstants.USER_LOGIN, true);
      setItem(localStorageConstants.USER_PROFILE_PRESENT, true);
      setItem(localStorageConstants.ACCESS_TOKEN, response.accesstoken);
      setItem(localStorageConstants.ID_TOKEN, response.idtoken);
      const firstname =
        toCapitalize(response.firstname) || messageConstants.EMPTY;
      const lastname =
        toCapitalize(response.lastname) || messageConstants.EMPTY;
      setItem(localStorageConstants.STORE_NO,
        removeLeadingZeros(response.storenum) || messageConstants.EMPTY);
      setItem(localStorageConstants.USER_ID,
        response.userid || messageConstants.EMPTY
      );
      if(response.userid.startsWith('vn') && !response.winnum) { // this check denotes user is a vendor
        //TO-DO: revisit this check with an explicit parameter denoting if user is vendor
        setItem(localStorageConstants.WIN,'-1');
        response.winnum = '-1';
      } else {
        setItem(localStorageConstants.WIN,
          response.winnum || messageConstants.EMPTY);
      }
      setItem(localStorageConstants.WIN,
        response.winnum || messageConstants.EMPTY);
      setItem(localStorageConstants.FIRST_NAME, firstname);
      setItem(localStorageConstants.LAST_NAME, lastname);
      setItem(
        localStorageConstants.FULL_NAME,
        firstname + " " + lastname || messageConstants.EMPTY);
      setItem(
        localStorageConstants.JOB_CODE,
        response.systemjobcode || messageConstants.EMPTY);
      setItem(
        localStorageConstants.LAWSON_JOBCODE,
        response.lawsonjobcode || messageConstants.EMPTY);
      setItem(
        localStorageConstants.CLOCK_STATUS,
        response.clockin || messageConstants.EMPTY);
        setItem(
          localStorageConstants.PAY_TYPE,
          response.assocPayType || messageConstants.EMPTY
        );
      
      if (getRequestedPath() && getRequestedPath().indexOf("byod") > 0) {
        setItem(localStorageConstants.DEVICE_TYPE, "byod");
      }
      if (response.winnum) {
        await getUserRole(response.winnum);
      }
      if(getDivisionName()!== null){
        setUserLoginData()
      }
    }
  };

    getUserProfileForUIN = async (type, params) => {
    const response = await fetchUserProfileData(type, params);
    if (await response) {
      setItem(localStorageConstants.USER_LOGIN, true);
      setItem(localStorageConstants.USER_PROFILE_PRESENT, true);


      const firstname =
        toCapitalize(response.first_name) || messageConstants.EMPTY;
      const lastname =
        toCapitalize(response.last_name) || messageConstants.EMPTY;
      setItem(localStorageConstants.STORE_NO,
        removeLeadingZeros(response.storenum) || messageConstants.EMPTY);
      setItem(localStorageConstants.USER_ID,
        response.userid || messageConstants.EMPTY
      );
      setItem(localStorageConstants.WIN,
        response.win || messageConstants.EMPTY);
      setItem(localStorageConstants.FIRST_NAME, firstname);
      setItem(localStorageConstants.LAST_NAME, lastname);
      setItem(
        localStorageConstants.FULL_NAME,
        firstname + " " + lastname || messageConstants.EMPTY);

        document.cookie = 'uin' + "=" + response.win + ";domain=.wal-mart.com;path=/;max-age=31536000"
        document.cookie = 'uin1' + "=" + response.win + ";domain=.walmart.com;path=/;max-age=31536000"
     
      if (getRequestedPath() && getRequestedPath().indexOf("byod") > 0) {
        setItem(localStorageConstants.DEVICE_TYPE, "byod");
      }
      if (response.win) {
        await getUserRole(response.win);
      }
      if(getDivisionName()!== null){
        setUserLoginData()
      }
    }
    return response
  };
  async logout() {
    setUserLogoutData();
    const isExternalUser = getItem(localStorageConstants.IS_EXTERNAL_USER);
    clear();
    if(isExternalUser === 'true'){
      await auth('', 'logout', 'axiosPut');
    }
    if (!getItem(localStorageConstants.ACCESS_TOKEN)) {
      window.location.href = "/login";
    }
  }

}
